<template lang="pug">
    v-container.primario( style='position: relative' v-if='Pie_de_página' fluid).pa-0.ma-0
      v-row.primario.ma-3.mt-0
        Columna_mapa_de_sitio.mt-3
        v-spacer
        Columna_de_encuentranos_en.mt-3.mr-12
      v-divider.divider.rojo
</template>
<script>
export default {
  components: {
    Columna_mapa_de_sitio: ()=>import('./mapa_de_sitio/columna_mapa_de_sitio'),
    Columna_de_encuentranos_en: ()=>import('./encuéntranos_en/columna_de_encuéntranos_en'),
  },
}
</script>
<style lang="sass">
  .divider
    max-height: 4px!important
    height: 4px!important
</style>
<style lang="sass" scoped>
.before-enter
  opacity: 0
  transition: all 1.5s ease-out   
  &[direccion='izquierda']
    transform: translatex(-100%)
  &[direccion='derecha']
    transform: translateX(100%)
.enter
  opacity: 1
  transform: translateX(0px) 
</style>